$(document).on('DOMContentLoaded', function() {
  const fileInput = document.getElementById("quiz-file-upload");

  if (fileInput) {
    fileInput.addEventListener('change', function(event) {
      const file = event.target.files[0];

      if (file) {
          const reader = new FileReader();

          reader.onload = function(e) {
            const data = JSON.parse(e.target.result);
            // console.log("data", data);

            // Populate form fields with the parsed JSON data
            document.getElementById("quiz_title").value = data.quiz.title || '';
            document.getElementById("quiz_description").value = data.quiz.description || '';
            document.getElementById("quiz_entry_fee").value = data.quiz.entry_fee || '';
            document.getElementById("quiz_base_prize_pool").value = data.quiz.base_prize_pool || '';

            // Handling select fields for subjects and tiers
            if (data.quiz.subject_id) {
              document.getElementById("quiz_subject_id").value = data.quiz.subject_id;
            }

            if (data.quiz.tier_id) {
              document.getElementById("quiz_tier_id").value = data.quiz.tier_id;
            }

            if (data.quiz.quiz_type) {
                const formattedQuizType = data.quiz.quiz_type.toLowerCase().replace(/\s+/g, '_');
                document.getElementById("quiz_quiz_type").value = formattedQuizType;
            }
          };

          reader.readAsText(file);
      }
    });
  }
});

